import React, { useState, useContext } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line
import BackgroundImage from "gatsby-background-image"

import { CartContext } from "../../CartProvider"
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js"
import { PrimaryButton } from "../misc/Buttons.js"
import ChevronLeftIcon from "react-feather/dist/icons/chevron-left"
import ChevronRightIcon from "react-feather/dist/icons/chevron-right"
import LinkIcon from "react-feather/dist/icons/link-2"
// import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-4.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-6 lg:py-8`
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`
const Testimonials = styled.div``
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`

const ImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `

const ImageAndControlContainer = tw.div`relative outline-none`
const Image = styled(BackgroundImage)`
  ${tw`rounded-xl bg-cover bg-center h-80 sm:h-96 lg:h-144`}
`

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl rounded-br-xl border-default`
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full! p-2!`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
])

const Subheading = tw(SubheadingBase)`mb-4`
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`
const Price = tw.h3`my-3 font-bold text-beige-600 text-2xl`

const FormContainer = styled.div`
  ${tw`my-6 py-5 px-5 sm:px-6 md:px-8 bg-beige-100 text-beige-800 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-beige-600 text-base font-medium tracking-wide border-b-2 py-2 border-gray-500 hocus:border-primary-600 focus:outline-none transition-default duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`
const Column = tw.div`flex flex-col justify-between`
const InputContainer = tw.div`relative py-5`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`
const TextInput = tw.input``
const ThreeColumnContainer = tw.div`flex flex-col lg:flex-row items-stretch justify-between`
const SmallInputContainer = tw(InputContainer)`lg:max-w-24`
const ColorPicker = tw.div`flex pt-3 -mb-2`
const ColorItem = tw.button`border-4 border-gray-400 rounded-full w-8 h-8 hocus:(outline-none border-primary-600)`
const selectedColorStyles = css`
  ${tw`border-primary-600`}
`
const VideoLink = tw.div`text-gray-600 text-sm`
const BuyButton = tw(
  PrimaryButton
)`text-sm text-primary-100 disabled:bg-gray-400 disabled:text-gray-500 disabled:cursor-not-allowed`

const lengthExceptions = ["shoe size"]
const colorClassMap = {
  white: tw`bg-white`,
  beige: tw`bg-beige-400`,
  gray: tw`bg-gray-500`,
  black: tw`bg-gray-900`,
  ivory: tw`bg-beige-200`,
}

const parseMetadataProp = (product, propName, defaults = []) => {
  const values = (product.metadata[propName] ?? "")
    .split(",") 
    .map(s => s.trim())
    .filter(s => s)
  return values.length ? values : defaults
}

// eslint-disable-next-line react/display-name
export default ({ product = null, textOnLeft = false }) => {
  const colors = parseMetadataProp(product, "colors", [
    "white",
    "beige",
    "black",
    "ivory",
  ])
  const dimensions = parseMetadataProp(product, "measurements")
  const { add, toggle } = useContext(CartContext)

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null)
  const [color, setColor] = useState(colors[0])
  const [valid, setValid] = useState(dimensions.length === 0)
  const [measurements, setMeasurements] = useState({})

  const onMeasurementsChange = e => {
    const key = e.target.name
    const newMeasurements = {
      ...measurements,
      [key]: e.target.value,
    }
    setMeasurements(newMeasurements)
    setValid(checkValidity(newMeasurements))
  }
  const checkValidity = obj => {
    const setValues = Object.values(obj)
    return (
      setValues.length === dimensions.length &&
      setValues.filter(v => !v).length === 0
    )
  }

  const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)
  const addToCart = () => {
    const colorStr = `Color: ${color}.`
    const measurementsStr = Object.entries(measurements)
      .map(([key, value]) => `${capitalize(key)}: ${value} in;`)
      .join("\n")
    const notes = [measurementsStr, colorStr].join("\n")
    add(prices[0].id, notes)
  }

  // Product part
  const prices = [...product.prices].sort(
    (a, b) => a.unit_amount - b.unit_amount
  )
  const soldOut = !product.prices[0].active
  const images = [...product?.localFiles]

  return (
    <Container>
      <Content>
        <HeadingInfo
          tw="text-center lg:hidden"
          heading={product.name}
          description={product.description}
          prices={prices}
        />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <ImageSlider arrows={false} ref={setImageSliderRef} fade>
                {images.map((image, index, self) => (
                  <ImageAndControlContainer key={index}>
                    <Image
                      fluid={image.childImageSharp.fluid}
                      tw="before:rounded-xl!"
                    />
                    {self.length > 1 && (
                      <ControlContainer>
                        <ControlButton onClick={imageSliderRef?.slickPrev}>
                          <ChevronLeftIcon />
                        </ControlButton>
                        <ControlButton onClick={imageSliderRef?.slickNext}>
                          <ChevronRightIcon />
                        </ControlButton>
                      </ControlContainer>
                    )}
                  </ImageAndControlContainer>
                ))}
              </ImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo
                  tw="hidden lg:block"
                  heading={product.name}
                  description={product.description}
                  prices={prices}
                />
                <FormContainer>
                  <Column>
                    <InputContainer>
                      <Label>Color: {capitalize(color)}</Label>
                      <ColorPicker>
                        {colors.map((c, i) => (
                          <ColorItem
                            key={c}
                            css={[
                              colorClassMap[c],
                              i > 0 && tw`ml-2`,
                              color === c && selectedColorStyles,
                            ]}
                            onClick={() => setColor(c)}
                            title={capitalize(c)}
                          ></ColorItem>
                        ))}
                      </ColorPicker>
                    </InputContainer>
                    <ThreeColumnContainer>
                      {dimensions.map((d, i) => (
                        <SmallInputContainer key={d}>
                          <Label htmlFor={`${d}-input`}>{capitalize(d)}</Label>
                          <TextInput
                            type="text"
                            id={`${d}-input`}
                            name={d}
                            value={measurements[d]}
                            onChange={onMeasurementsChange}
                          />
                          {!lengthExceptions.includes(d) && (
                            <div tw="text-sm text-gray-500">inches</div>
                          )}
                        </SmallInputContainer>
                      ))}
                    </ThreeColumnContainer>
                    {dimensions.length > 0 && (
                      <VideoLink>
                        Here is{" "}
                        <a
                          href="https://www.youtube.com/watch?v=FF7JrcKS26o"
                          target="_blank"
                          rel="noreferrer"
                          tw="text-primary-600 hocus:text-primary-700"
                        >
                          a helpful video about taking measurements{" "}
                          <LinkIcon tw="inline-block" size="16" />
                        </a>
                      </VideoLink>
                    )}
                  </Column>
                </FormContainer>
                <BuyButton
                  onClick={() => {
                    addToCart()
                    toggle(true)
                  }}
                  disabled={soldOut || !valid}
                >
                  {soldOut ? "Sold Out" : "Add to Cart"}
                </BuyButton>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  )
}

const HeadingInfo = ({
  subheading,
  heading,
  description,
  prices,
  ...props
}) => {
  const soldOut = !prices[0].active
  return (
    <div {...props}>
      {subheading ? <Subheading>{subheading}</Subheading> : null}
      <HeadingTitle>{heading}</HeadingTitle>
      <Price css={[soldOut && tw`line-through text-gray-600`]}>
        {prices.length > 1 && "From "}${prices[0].unit_amount / 100}
      </Price>
      <Description>{description}</Description>
    </div>
  )
}
