import React, { useContext } from "react"
import PropTypes from "prop-types"

import SEO from "./SEO"
import Preview from "./treact/testimonials/TwoColumnWithImageAndProfilePictureReview"
import { ProductsContext } from "./ProductsProvider"

const ProductPage = ({ productId }) => {
  const { products } = useContext(ProductsContext)
  const product = products[productId]
  return (
    product && (
      <div>
        <SEO
          title={product.name}
          description={product.description}
          image={product.images[0]}
        ></SEO>
        <Preview product={product} />
        {/* <div className={css.image} onClick={onImageClick}>
        {activeImage && (
          <Img
            fluid={activeImage}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        )}
      </div>

      <h2>{product.name}</h2>

      <p>
        <em>
          {prices.length > 1 && "From "}${prices[0].unit_amount / 100}
        </em>
      </p>

      <p className={css.description}>{product.description}</p>

      <div className={css.notes}>
        {available(activePrice.id) && (
          <label>
            Notes
            <textarea
              rows={3}
              value={notes}
              onChange={onNotesChange}
              placeholder="Please provide your measurements"
              minLength={1}
            />
          </label>
        )}
      </div>

      <div className={css.controls}>
        {product.prices.length > 1 && (
          <label>
            Item Style
            <select name="price" id="price" onChange={onPriceChange}>
              {product.prices.map((price, i) => {
                return (
                  <option value={i} key={price.id}>
                    {price.nickname} &ndash; ${price.unit_amount / 100}
                  </option>
                )
              })}
            </select>
          </label>
        )}

        <button
          onClick={() => {
            add(activePrice.id, notes)
            toggle(true)
          }}
          disabled={!available(activePrice.id) || notes.length === 0}
        >
          {available(activePrice.id) ? "Add To Cart" : "Sold Out"}
        </button>
      </div> */}
      </div>
    )
  )
}

ProductPage.propTypes = {
  productId: PropTypes.string.isRequired,
}

export default ProductPage
