import React from "react"
import PropTypes from "prop-types"
// import StoreLayout from "../components/StoreLayout"
import { CatalogLayout } from "../components/CatalogLayout"

import ProductPage from "../components/ProductPage"

const ItemTemplate = ({ pageContext: { id } }) => {
  return (
    <CatalogLayout>
      <ProductPage productId={id} />
    </CatalogLayout>
  )
}

ItemTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default ItemTemplate
