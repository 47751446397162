import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line

import ProductsProvider from "./ProductsProvider"
import CartProvider from "./CartProvider"

import AnimationRevealPage from "./helpers/AnimationRevealPage.js"
import Footer from "./treact/footers/MiniCenteredFooter.js"
import Header from "./treact/headers/light"

const HeaderContainer = tw.div`-mt-2 pb-5`

export const CatalogLayout = ({ children }) => (
  <AnimationRevealPage>
    <ProductsProvider>
      <CartProvider>
        <HeaderContainer>
          <Header cart roundedHeaderButton/>
        </HeaderContainer>
        {children}
        <Footer />
      </CartProvider>
    </ProductsProvider>
  </AnimationRevealPage>
)
CatalogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
